<template>
<div class="page-wrapper">
  <main class="main-wrapper">
    <section class="section dont-print">
      <div class="container-copy is-hero bg-image">
        <div class="hero_h1-contain">
          <img src="/images/10-ways-to-write-your-name-medium.png" loading="lazy" alt="10 Ways to write your name">
        </div>
      </div>
      <div class="container is-wide">
        <div class="cta_text-contain">
          <div class="main_p-contain">
            <p class="paragraph-2-copy big-and-bold">
              <strong>There are a lot of ways to write your name, especially in Asia.</strong> <span class="no-wrap">The biggest continent</span>
              also has the biggest list of <span class="no-wrap">writing systems</span>.
            </p>
          </div>
        </div>
      </div>
      <div class="container-your-name">
        <div class="grid grid-top">
          <div class="text-contain">
            <h2 class="heading-4">
              <!-- <span class="bold">Instructions:</span><br> -->
              Type your first name to see how it’s written in ten Asian scripts.
            </h2>
            <img src="/images/arrow.png" loading="lazy" alt="" class="drown-arrow">
          </div>
          <div id="w-node-d7a22ab3-b938-efbf-cd95-8e6d52f98871-f4e61d62" class="grid_item">
            <div class="text-contain">
              <div class="name-generate_contain">
                <img src="/images/name-tag.png" loading="lazy" alt="" class="name-tag-myname">
                <div class="name-tag-label-form"></div>
                <div class="html-embed-3">
                  <label for="your-name" htmlFor="your-name" class="">
                    <input type="text" id="your-name" name="your-name" v-model="current_name" v-on:keyup.enter="generateNames">
                    Your Name
                  </label>
                </div>
              </div>
              <button id="generate-names" :class="['button is-dark w-button', buttonStyle]" @click="generateNames">Generate</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="translations-info">Translations may be inaccurate. Not all names may be generated.</p>
      </div>
    </section>
    <section id="translations" class="section">
      <div class="container low-margin-bottom">
        <div class="grid">
          <NameTag
            v-for="translation in translations" :key="translation.language"
            :language="translation.language"
            :name="loaded_name"
            :translation="translation.name"
          />
        </div>
      </div>
    </section>
    <section id="print-button" class="section dont-print" v-if="translations.length > 0">
      <div class="container is-wide">
        <div class="cta_text-contain">
          <div class="btn-contain">
            <button class="button is-dark w-button" @click="printNameTags">Print</button>
          </div>
        </div>
      </div>
    </section>
    <section id="footer" class="section dont-print">
      <div class="container is-wide">
        <div class="cta_text-contain">
          <div class="footer-contain">
            <a class="footer-logo" href="https://www.c-k.com"><img src="/images/footer-logo.png" alt="Cramer-Krasselt"></a>
            <img class="footer-locations" src="/images/footer-locations.png" alt="Chicago-Milwaukee">
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
import NameTag from './components/NameTag.vue';

export default {
  name: 'App',
  components: {
    NameTag,
  },
  data() {
    return {
      current_name: '',
      loaded_name: '',
      languages: [
        'bengali',
        'chinese',
        'hindi',
        'japanese',
        'korean',
        'sinhala',
        'tamil',
        'thai',
        'telugu',
        'urdu',
      ],
      translations: [],
      buttonStyle: '',
    };
  },
  mounted() {
    // hack
    setTimeout(() => {
      const $button = document.getElementById('generate-names');
      $button.addEventListener('animationend', () => {
        this.buttonStyle = '';
        console.log('animationend');
      });
    }, 500);
  },
  methods: {
    generateNames() {
      const name = this.current_name.trim().toUpperCase();

      if (name.length === 0) {
        this.buttonStyle = 'wobble';
        return;
      }

      const dev = 'http://c-k-translate.loc';
      const prod = 'https://myname.c-k-dev.com/api';
      const apiUrl = (window.location.hostname === 'localhost') ? dev : prod;

      this.loaded_name = name;
      const link = `${apiUrl}?name=${name}`;
      // fetch(link, { headers: { 'Content-Type': 'application/json; charset=utf-8' } })
      //   .then((res) => res.json()) // parse response as JSON (can be res.text() for plain response)
      //   .then((response) => {
      //     // here you do what you want with response
      //     console.log(response);
      //     alert('It Worked');
      //   })
      //   .catch((err) => {
      //     console.log('err:');
      //     console.error(err);
      //     alert('sorry, there are no results for your search');
      //   });

      // Scroll to
      // Scroll to a certain element
      setTimeout(() => {
        document.querySelector('#translations').scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);

      const xhr = new XMLHttpRequest();
      xhr.open('GET', link);
      // state change event
      xhr.onreadystatechange = () => {
        // is request complete?
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) {
          // request successful
          const translations = JSON.parse(xhr.responseText);

          this.translations = [];
          for (let i = 0, l = this.languages.length; i < l; i += 1) {
            const language = this.languages[i];
            this.translations.push({ language, name: translations[language] });
          }

          console.log(this.translations);
        } else {
          // request not successful
          console.log('HTTP error', xhr.status, xhr.statusText);
        }
      };
      // start request
      xhr.send();
    },
    printNameTags() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
@import 'styles/main';

/**
* Add a transition to the label and input.
* I'm not even sure that touch-action: manipulation works on
* inputs, but hey, it's new and cool and could remove the
* pesky delay.
*/
label, input {
  transition: all 0.2s;
  touch-action: manipulation;
}

input {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 3px solid #22641d;
  font-size: 1.6rem;
  text-align: center;
  display: block;
  width: 100%;
  text-transform: uppercase;
}

input:focus {
  outline: 0;
  border-bottom-color: #666;
}

label {
  letter-spacing: 0.05em;
  text-align: center;
  width: 100%;
  font-size: 1em;
}

.translations-info {
  padding: 30px 30px 40px 30px;
  text-align: center;
  font-size: 0.85em;
}
</style>
