<template>
<div class="grid_item name-tag">
  <div class="name-tag-language">
    <h3>{{ language }}</h3>
  </div>
  <div class="name-tag-container">
    <div :class="`name-tag-label name-tag-label-${language}`"></div>
    <h4 :class="`${language}`">{{ displayName }}</h4>
  </div>
</div>
</template>

<script>
export default {
  name: 'NameTag',
  props: {
    language: String,
    name: String,
    translation: String,
    loading: Boolean,
  },
  computed: {
    displayName() {
      return this.name !== this.translation ? this.translation : '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@use "sass:math";

.name-tag {
  position: relative;
  width: 100%;
  print-color-adjust: exact !important;
  margin: 1.4em 0 0;

  @media screen and (min-width: 767px) {
    margin: 1em 0 0;
  }
}

.name-tag-language {
  margin-bottom: 1em;
}

$position: math.div(1, 10);

.name-tag-label {
  background-image: url('../../public/images/name-tag-label.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: 21%;

  &-bengali {
    background-position: 0 (100% * $position);
  }
  &-chinese {
    background-position: 0 (200% * $position);
  }
  &-hindi {
    background-position: 0 (300% * $position);
  }
  &-japanese {
    background-position: 0 (400% * $position);
  }
  &-korean {
    background-position: 0 (500% * $position);
  }
  &-sinhala {
    background-position: 0 (600% * $position);
  }
  &-tamil {
    background-position: 0 (600% * $position);
  }
  &-thai {
    background-position: 0 (700% * $position);
  }
  &-telugu {
    background-position: 0 (800% * $position);
  }
  &-urdu {
    background-position: 0 (900% * $position);
  }
}

.name-tag-container {
  position: relative;
  background-image: url('../../public/images/name-tag.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 0;
  width: 100%;
  padding-bottom: 66.666%;

  h4 {
    position: absolute;
    color: #22641d;
    top: 32%;
    bottom: 11%;
    left: 0;
    right: 0;
    font-size: 7.0vw;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    transform: translateY();
    // Verticle center
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @media screen and (min-width: 400px) {
      font-size: 2.8em;
    }

    @media screen and (min-width: 768px) {
      font-size: 2.4em;
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
